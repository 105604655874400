.navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 1rem;
    width: 100%;
    z-index: 1000;
    transition: 1s ease all;
}

.navbar-scroll {
    background-color: black;
    border-bottom: 1px solid whitesmoke;

}
.navbar-logo {
    font-size: 2.5rem;
    font-weight: bold;
    color: whitesmoke;
    margin-left: 5rem;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-transform: uppercase;
    transition: .5s ease all;
    position: relative;
    cursor: pointer;
}

.navbar-logo::before {
    content: attr(data-hover);
    position: absolute;
    top: 0;
    left: 0;
    color: rgb(255, 73, 73);
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    transition: width 0.2s ease-in-out;
}

.navbar-logo:hover::before {
    width: 100%;
    background-color: black;
}

.class-of {
    font-size: 1.25rem;
    display: flex;
    justify-content: center;

}

.navbar-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 50%;
    margin-right: 5%;
    justify-content: space-around;

}

.navbar-links li {
    font-size: 1.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.navbar-links a {
    color: whitesmoke;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
    transition: color 0.2s ease-in-out;
    cursor: pointer;

}

.navbar-links *::before {
    content: attr(data-hover);
    position: absolute;
    top: 0;
    left: 0;
    color: rgb(73, 73, 255);
    overflow: hidden;
    white-space: nowrap;
    width: 0;
    transition: width 0.2s ease-in-out;
}

.navbar-links *:hover::before {
    width: 100%;
    cursor: pointer;
    background-color: black;
    scale: 1.05;
    text-shadow: 0 5px 10px solid whitesmoke;
}

.red {
    color: rgb(128, 0, 0);
}

.blue {
    color: rgb(73, 73, 255);;
}

.navbar-links a {
    display: flex;
    align-items: center;
}

.navbar-links svg {
    width: 24px;
    height: 24px;
    color: whitesmoke;
    transition: color 0.2s ease-in-out;
}

.navbar-links svg:hover {
    color: rgb(167, 0, 0);
    cursor: pointer;
}

.social-link {
    padding: 30px 0px;
    transform: scale(150%);
}

.socials {
    position: fixed;
    right: 25px;
    top: 40%;
}

.mobile-menu-toggle {
    display: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 10px; /* Match the height of the gap */
    z-index: 1;
}

.dropdown:hover::after {
    background-color: rgba(0, 0, 0, 0); /* Transparent background */
}

.dropdown-content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid white;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease, max-height 0.5s ease, visibility 0.5s ease;
}

.dropdown:hover .dropdown-content {
    opacity: 1;
    visibility: visible;
    max-height: 300px; /* Adjust this value based on the actual height of your dropdown content */
}


.dropbtn {
    cursor: pointer;
}

  
  .dropdown-content a {
    color: whitesmoke;
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    text-align: left;
    font-size: 1rem;
    height: 1.75rem;
    border-top: .2px solid white;
  }
  
  .dropdown-content a:hover {
    background-color: #111;
  }
  
  .dropdown-content * {
    padding: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

@media (max-width: 1215px) {
    .class-of {
        font-size: 1rem;
    }
    .mobile-menu-toggle {
        display: block;
    }
    .navbar-links {
      display: none;
    }
  
    .socials {
      display: none;
    }
  
    .mobile-menu-toggle {
      display: block;
      background-color: transparent;
      border: none;
      color: whitesmoke;
      font-size: 1.5rem;
      font-weight: bold;
      padding: 1rem;
      cursor: pointer;
      z-index: 10000;
    }

    .navbar-logo {
        margin-top: 0%;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        text-align: center;
    }
    .navbar {
        padding: 1%;
    }
  }

  @media (max-width: 700px) {
    .navbar-logo {
        font-size: 1.5rem;
        margin: 3%;
        text-align: left;
    }
    .navbar-logo span {
        justify-content: flex-start;
    }
    .class-of {
        font-size: 1rem;
      }
  }