.site-footer {
    background-color: #111;
    padding: 1rem;
    color: #fff;
  }
  
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  
  .footer-text {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .footer-text p {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  .social-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  
  .social-links a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .social-links a:hover {
    color: #1da1f2;
  }
  
  @media (max-width: 1000px) {
    .footer-content {
      flex-direction: column;
      gap: 1rem;
    }
  }
  