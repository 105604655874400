/* In MobileMenu.css */
.mobile-menu {
    display: none;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    overflow: auto;
    padding: 2rem;
  }
  
  .mobile-menu.mobile-menu-visible {
    display: block;
    padding: 0;
  }
  
  .mobile-menu-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  
  .mobile-menu-links li {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .mobile-menu-links a {
    color: whitesmoke;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  